.form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #fafafa;  /* This will give you a more subtle, off-white color */
  border-radius: 10px;
  padding: 20px;
  width: 50%;
  margin: auto;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);  /* This will add a shadow border around the form */
}
  
  .form-container form {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
  
  .form-container label {
    margin-bottom: 10px;
  }

  .form-container textarea {

    resize: none;

  }
  
  .form-container input, .form-container textarea {
    width: 90%;  /* Make inputs take 90% of the width */
    padding: 10px;
    margin: 20px auto;  /* Centrally aligns the inputs and adds a margin at top and bottom */
    border-radius: 5px;
    border: 2px solid #ccc;
    font-family: inherit;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);  /* Add the same shadow effect as the form container */
  }

  .form-container input.submitButton {
    margin-bottom: 2rem;
    background-color: #D61549;
    color: #fff;
    font-size: 20px;
    box-sizing: border-box;
  }
  
  /* Mobile Styles */
@media only screen and (max-width: 599px) {
  .form-container input, .form-container textarea {
    width: 90%;
  }

  .form-container input.submitButton {
    width: 90%; /* Same width as your other input elements */
  }
}

/* Tablet Styles */
@media only screen and (min-width: 600px) and (max-width: 959px) {
  .form-container input, .form-container textarea {
    width: 75%;  /* Adjust this value as per your requirement */
    max-width: 500px;  /* Set a max-width for larger screens */
  }
  
  .form-container input.submitButton {
    width: 75%; /* Same width as your other input elements */
  }
}

/* Laptop Styles */
@media only screen and (min-width: 960px) and (max-width: 1279px) {
  .form-container input, .form-container textarea {
    width: 60%;  /* Adjust this value as per your requirement */
    max-width: 600px;  /* Set a max-width for larger screens */
  }


  .form-container input.submitButton {
    width: 60%; /* Same width as your other input elements */
  }
}

/* Desktop Styles */
@media only screen and (min-width: 1280px) {
  .form-container input, .form-container textarea {
    width: 50%;  /* Adjust this value as per your requirement */
    max-width: 700px;  /* Set a max-width for larger screens */
  }

    .form-container input.submitButton {
    width: 50%; /* Same width as your other input elements */
  }
}
