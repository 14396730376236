body {
  font-family: 'Inter', sans-serif;
  background-color: black;
  color: white;
  text-align: center;
  overflow-x: hidden;
}

h1 {
  font-size: 4rem;
}

.spacer {
  padding-bottom: 10rem;
}

.logotop {
  width: 256px; 
  height: 256px;
}

.gradient-text {
  background: linear-gradient(to right, #9558c9, #fa7500);
  -webkit-background-clip: text;
         background-clip: text; 
  -webkit-text-fill-color: transparent;
}

.gradient-text2 {
  background: linear-gradient(to right, #fa7500, #f0f0f0);
  -webkit-background-clip: text;
          background-clip: text; 
  -webkit-text-fill-color: transparent;
}


.white-text {
  color: #fff;
}

.card-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  
}

.card {
  background-color: rgba(34, 34, 34, 0.8);
  color: black;
  border-radius: 8px;
  padding: 20px;
  margin: 10px;
  width: calc((100% / 5) - 20px); /* This will make 5 cards fit in a row, you can adjust it according to your needs */
  box-sizing: border-box;
}

.card-title {
  margin: 0 0 10px 0;
  font-size: 24px;
  color: white;
}

.card-text {
  margin: 0;
}


.container {
  height: auto;
}

.text {
  margin-left: 1rem;
  margin-right: 1rem;
}

.text-columnar {
  margin-left: 14rem;
  margin-right: 14rem;
}

.white-bg {
  background-color: white;
  color: #000;
}

.footer-links {
  display: flex;
  justify-content: space-evenly;
}

.footer-column {
  flex-basis: 30%; /* Adjust based on your design */
}

.footer-link {
  display: block;
  text-decoration: none;
  color: #fff; /* Replace with the color you want */
  padding: 5px 0; /* Add some padding to make the links easier to click */
}

.align-left {
  text-align: left;
  margin-left: 4rem;
}


hr {
  
    background-color: black;
    width: 50%; /* Adjust the width value as per your requirement */
  
}

.hr-white {
  background-color: #fff;
    width: 100%; /* Adjust the width value as per your requirement */
}

.pad-top {
  padding-top: 5rem;
}

.pad-bottom {
  padding-bottom: 5rem;
}

.rounded-button {
  border-radius: 50px;
  background-color: rgb(214,21,73);
  color: white;
  font-size: 2rem;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  margin-bottom: 14rem;
  font-family: 'Inter', sans-serif;
  transition: background-color .35s;
}

.rounded-button:hover {
  background-color: rgba(98, 117, 211, 0.757);
  transition: background-color .35s;
}

.fadeIn {
  animation: fadeIn 5s;
}

@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

/* Mobile Styles */
@media only screen and (max-width: 599px) {
  #splashtextlarge {
    color: white;
    margin-top: 5rem;
    margin-bottom: 15rem;
    font-size: 4rem;
  }

  .card {
    width: 100%; /* This will make the cards stack vertically on smaller screens */
  }

  img {
    width: 250px; 
    height: 250px;
    border-radius: 1000px;
  }
  }

  .text-columnar {
    margin-left: 3rem;
    margin-right: 3rem;
  }

  h2.oran-text {
    font-size: 1rem;
  }

/* Tablet Styles */
@media only screen and (min-width: 600px) and (max-width: 959px) {
  #splashtextlarge {
    color: white;
    margin-top: 5rem;
    margin-bottom: 15rem;
    font-size: 6rem;
  }


  .card {
    width: 100%; /* This will make the cards stack vertically on smaller screens */
  }

  img {
    width: 300px; 
    height: 300px;
    border-radius: 1000px;
  }
  

  .text-columnar {
    margin-left: 5rem;
    margin-right: 5rem;
  }

  h2.oran-text {
    font-size: 1.5rem;
  }

}


/* Laptop Styles */
@media only screen and (min-width: 960px) and (max-width: 1279px) {
  #splashtextlarge {
    color: white;
    margin-top: 5rem;
    margin-bottom: 10rem;
    font-size: 9rem;
  }

  .rounded-button {
    margin-top: 1rem;
  }

  img {
    width: 35rem; 
    height: 35rem;
    border-radius: 1000px;
  }

}

/* Desktop Styles */
@media only screen and (min-width: 1280px) {
  #splashtextlarge {
    color: white;
    margin-top: 3rem;
    margin-bottom: 6rem;
    font-size: 10rem;
  }

  img {
    width: 50rem; 
    height: 50rem;
    border-radius: 1000px;
  }

}
